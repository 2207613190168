import React from 'react';
import pt from 'prop-types';
import TeaserItem from '../../../../ui/teaserItem';
import {
  DYNAMIC_PAGE_TYPES,
  getCountryName,
} from '../../../../../utils/constants';
import { useLocale } from '../../../../../context/locale.context';
import placeholderImage from '../../../../../images/placeholder-image.png';
import { getDynamicPageLink } from '../../../../../helpers/getDynamicPageLink';

const JuryTeaserItem = ({
  title,
  firstName,
  surname,
  id,
  pictureProfile,
  country,
}) => {
  const { locale } = useLocale();
  return (
    <TeaserItem
      link={{
        linkToPage: getDynamicPageLink(
          DYNAMIC_PAGE_TYPES.JURY_DETAIL,
          `${firstName}-${surname}`,
          id
        ),
      }}
      title={[title, firstName, surname].filter(Boolean).join(' ')}
      image={{
        url: pictureProfile || placeholderImage,
        description: firstName,
        isContainImage: !pictureProfile,
      }}
      subline={getCountryName(locale, country) || ''}
      aspectRatio="1/1"
    />
  );
};

JuryTeaserItem.propTypes = {
  title: pt.string,
  firstName: pt.string,
  surname: pt.string,
  id: pt.string,
  pictureProfile: pt.string,
  country: pt.string,
};

JuryTeaserItem.defaultProps = {
  title: '',
  firstName: '',
  surname: '',
  id: '',
  pictureProfile: '',
  country: '',
};

export default JuryTeaserItem;
